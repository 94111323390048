import Content from "../layouts/Content";
import { Helmet } from "react-helmet-async";

const Mp4Component = (props) => {
  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="Convert YouTube videos to MP4 format quickly and easily with our free online converter tool at Y2mate.buzz. Start converting  and enjoy swift downloads!"
        />
        <title>
          YouTube to MP4 Converter [100% Free and Fast] - y2mate.buzz
        </title>
      </Helmet>
      <Content content={props.content} mode={props.mode} tab={props.tab} />
    </>
  );
};

export default Mp4Component;
