import Content from "../layouts/Content";
import { Helmet } from "react-helmet-async";

const ShortsComponent = (props) => {
  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="You can easily download YouTube Shorts with our free online downloader tool at Y2mate.buzz. There is no hassle, just quick and easy downloads. Try it now!"
        />
        <title>Download YouTube Shorts - Free & Easy | Y2mate.buzz</title>
      </Helmet>
      <Content content={props.content} mode={props.mode} tab={props.tab} />
    </>
  );
};

export default ShortsComponent;
