import React from "react";

const Description = (props) => {
  return (
    <React.Fragment>
      <div className="row row-padded row-bordered row-centered p-l-md p-r-md">
        <div className="col-sm-12">
          <div className="text-center">
            <h2>
              <strong style={{fontWeight: 'bold'}}>{props.subtitle}</strong>
            </h2>
            <p>{props.description}</p>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Description;
