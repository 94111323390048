import React from "react";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";


const PrivacyPolicy = () => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <Helmet>
        <meta
          name="description"
          content="Here at Y2mate.buzz, operating as Y2mateDownloader (“Company,” “we,” “us,” “our”), we understand the importance of safeguarding your personal information. Your privacy is a top priority for us. Please take a moment to review our Privacy Policy"
        />
        <title>Privacy Policy - Y2Mate.buzz</title>
      </Helmet>
      <div className="container main-container">
        <div className="row">
          <div className="col-sm-12" style={{ background: "#ffffff" }}>
            <div
              className="blog-post"
              style={{ padding: "39px 10px", minHeight: "500px" }}
            >
              <p>
                <strong>{t("privacy_policy.title")}</strong>
              </p>
              <p>{t("privacy_policy.content.heading")}</p>
              <p>{t("privacy_policy.content.acceptance")}</p>
              <p>{t("privacy_policy.content.terms_reference")}</p>
              <p>{t("privacy_policy.content.privacy_principles.heading")}</p>
              <p>{t("privacy_policy.content.privacy_principles.title")}</p>
              <ul>
                <li>
                  {t("privacy_policy.content.privacy_principles.principles.p1")}
                </li>
                <li>
                  {t("privacy_policy.content.privacy_principles.principles.p2")}
                </li>
                <li>
                  {t("privacy_policy.content.privacy_principles.principles.p3")}
                </li>
              </ul>
              <p>
                {t(
                  "privacy_policy.content.what_does_this_privacy_policy_cover.heading"
                )}
              </p>
              <p>
                {t(
                  "privacy_policy.content.what_does_this_privacy_policy_cover.description"
                )}
              </p>
              <p>
                {t(
                  "privacy_policy.content.what_does_this_privacy_policy_cover.children_privacy"
                )}
              </p>
              <p>
                {t(
                  "privacy_policy.content.what_information_does_y2matedownloader_collect.heading"
                )}
              </p>
              <p>
                {t(
                  "privacy_policy.content.what_information_does_y2matedownloader_collect.subpoint"
                )}
              </p>
              <ul>
                <li>
                  {t(
                    "privacy_policy.content.what_information_does_y2matedownloader_collect.data_categories.category1"
                  )}
                </li>
                <li>
                  {t(
                    "privacy_policy.content.what_information_does_y2matedownloader_collect.data_categories.category2"
                  )}
                </li>
                <p>
                  {t(
                    "privacy_policy.content.what_information_does_y2matedownloader_collect.data_usage"
                  )}
                </p>
                <p>
                  {t(
                    "privacy_policy.content.what_information_does_y2matedownloader_collect.examples.example1"
                  )}
                </p>
                <p>
                  {t(
                    "privacy_policy.content.what_information_does_y2matedownloader_collect.examples.example2"
                  )}
                </p>
                <p>
                  {t(
                    "privacy_policy.content.what_information_does_y2matedownloader_collect.examples.example3"
                  )}{" "}
                  <br />
                  <span style={{ fontSize: "12px" }}>
                    {t(
                      "privacy_policy.content.what_information_does_y2matedownloader_collect.examples.link"
                    )}
                  </span>
                </p>

                <li>
                  {t(
                    "privacy_policy.content.what_information_does_y2matedownloader_collect.examples.example4"
                  )}
                  <ol>
                    <li>
                      {t(
                        "privacy_policy.content.what_information_does_y2matedownloader_collect.examples.category1"
                      )}
                    </li>
                    <li>
                      {t(
                        "privacy_policy.content.what_information_does_y2matedownloader_collect.examples.category2"
                      )}
                    </li>
                    <li>
                      {t(
                        "privacy_policy.content.what_information_does_y2matedownloader_collect.examples.category3"
                      )}
                    </li>
                    <li>
                      {t(
                        "privacy_policy.content.what_information_does_y2matedownloader_collect.examples.category4"
                      )}
                    </li>
                    <li>
                      {t(
                        "privacy_policy.content.what_information_does_y2matedownloader_collect.examples.category5"
                      )}
                    </li>
                  </ol>
                </li>
                <li>
                  {t(
                    "privacy_policy.content.what_information_does_y2matedownloader_collect.exclusions.heading"
                  )}
                  <ol>
                    <li>
                      {t(
                        "privacy_policy.content.what_information_does_y2matedownloader_collect.exclusions.list.l1"
                      )}
                    </li>
                    <li>
                      {t(
                        "privacy_policy.content.what_information_does_y2matedownloader_collect.exclusions.list.l2"
                      )}
                    </li>
                    <li>
                      {t(
                        "privacy_policy.content.what_information_does_y2matedownloader_collect.exclusions.list.l3"
                      )}
                    </li>
                    <li>
                      {t(
                        "privacy_policy.content.what_information_does_y2matedownloader_collect.data_collection_location"
                      )}
                      <br />-{" "}
                      {t(
                        "privacy_policy.content.what_information_does_y2matedownloader_collect.data_not_collected.list.l1"
                      )}
                      <br />-{" "}
                      {t(
                        "privacy_policy.content.what_information_does_y2matedownloader_collect.data_not_collected.list.l2"
                      )}
                      <br />-{" "}
                      {t(
                        "privacy_policy.content.what_information_does_y2matedownloader_collect.data_not_collected.list.l3"
                      )}
                      <br />-{" "}
                      {t(
                        "privacy_policy.content.what_information_does_y2matedownloader_collect.data_not_collected.list.l4"
                      )}
                      <br />-{" "}
                      {t(
                        "privacy_policy.content.what_information_does_y2matedownloader_collect.data_not_collected.list.l5"
                      )}
                      <br />
                    </li>
                    <li>
                      {t(
                        "privacy_policy.content.what_information_does_y2matedownloader_collect.privacy_assurance"
                      )}
                    </li>
                    <li>
                      {t(
                        "privacy_policy.content.what_information_does_y2matedownloader_collect.data_retention"
                      )}
                      <p>
                        {t(
                          "privacy_policy.content.what_information_does_y2matedownloader_collect.remember"
                        )}
                      </p>
                    </li>
                  </ol>
                </li>
              </ul>
              <p>
                {t(
                  "privacy_policy.content.do_we_share_the_information_we_collect_with_others.heading"
                )}
              </p>
              <p>
                {t(
                  "privacy_policy.content.do_we_share_the_information_we_collect_with_others.description"
                )}
              </p>
              <p>
                {t(
                  "privacy_policy.content.security_disclaimer_and_third_party_links.heading"
                )}
              </p>
              <p>
                {t(
                  "privacy_policy.content.security_disclaimer_and_third_party_links.description"
                )}
              </p>
              <p>
                {t(
                  "privacy_policy.content.visiting_from_outside_the_usa.heading"
                )}
              </p>
              <p>
                {t(
                  "privacy_policy.content.visiting_from_outside_the_usa.description"
                )}
              </p>
              <p>
                {t("privacy_policy.content.changes_to_this_policy.heading")}
              </p>
              <p>
                {t("privacy_policy.content.changes_to_this_policy.description")}
              </p>
              <p>
                {t("privacy_policy.content.managing_your_information.heading")}
              </p>
              <p>
                {t(
                  "privacy_policy.content.managing_your_information.description"
                )}
              </p>
              <p>
                {t(
                  "privacy_policy.content.electronic_notice_in_case_of_security_breach.heading"
                )}
              </p>
              <p>
                {t(
                  "privacy_policy.content.electronic_notice_in_case_of_security_breach.description"
                )}
              </p>
              <p>{t("privacy_policy.content.got_questions.heading")}</p>
              <p>{t("privacy_policy.content.got_questions.description")}</p>
              <p> </p>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default PrivacyPolicy;
