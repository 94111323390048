import Content from "../layouts/Content";
import { Helmet } from "react-helmet-async";

const Main = (props) => {
  return (
    <>
      <Helmet>
        <meta name="description"
          content="Download YouTube videos easily with our free online tool. No software installation is required. Just paste the link and download it. Try Y2mate.buzz today."
        />
        <title>Free YouTube Video downloader Online - Y2mate.buzz</title>
      </Helmet>
      <Content content={props.content} mode={props.mode} tab={props.tab} />
    </>
  );
};

export default Main;
