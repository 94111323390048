import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t, i18n } = useTranslation();

  // Define the language prefix
  const langPrefix = i18n.language === "en" ? "en" : `/${i18n.language}`;

  return (
    <div className="footer mt-5">
      <p>© 2024 Y2Mate.buzz - Best video converter.</p>
      <div className="container">
        <div className="row p-l-md p-r-md">
          <div className="col-md-6">
            <p>
              <Link className="footer-list-link" to={`${langPrefix}`}>
                {t("home")}
              </Link>
            </p>
            <p>
              <Link className="footer-list-link" to={`${langPrefix}/youtube-to-mp3`}>
                {t("youtubeToMp3")}
              </Link>
            </p>
            <p>
              <Link className="footer-list-link" to={`${langPrefix}/youtube-to-mp4`}>
                {t("youtubeToMp4")}
              </Link>
            </p>
            <p>
              <Link className="footer-list-link" to={`${langPrefix}/youtube-shorts`}>
                {t("youtubeShorts")}
              </Link>
            </p>
          </div>
          <div className="col-md-6">
            <p>
              <Link className="footer-list-link" to={`${langPrefix}/about-us`}>
                {t("aboutUs")}
              </Link>
            </p>
            <p>
              <Link className="footer-list-link" to={`${langPrefix}/faq`}>
                {t("faq.title")}
              </Link>
            </p>
            <p>
              <Link className="footer-list-link" to={`${langPrefix}/contact-us`}>
                {t("contact.title")}
              </Link>
            </p>
            <p>
              <Link className="footer-list-link" to={`${langPrefix}/terms-of-service`}>
                {t("Terms_of_Use.title")}
              </Link>
            </p>
            <p>
              <Link className="footer-list-link" to={`${langPrefix}/privacy-policy`}>
                {t("privacy_policy.title")}
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
