import React from "react";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";

const TermOfService = () => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <Helmet>
        <meta
          name="description"
          content="Before you dive into using our website and services, take a moment to read through these terms and conditions.Y2mate.buzz, the Y2mate app, and the Y2mate browser extension"
        />
        <title>Terms of Use - Y2Mate.buzz</title>
      </Helmet>
      <div className="container main-container">
        <div className="row">
          <div className="col-sm-12" style={{ background: "#ffffff" }}>
            <div
              className="blog-post"
              style={{ padding: "39px 10px", minHeight: "500px" }}
            >
              <p>
                <strong>{t("Terms_of_Use.title")}</strong>
              </p>
              <p> </p>
              <p>{t("Terms_of_Use.Introduction")}</p>
              <p> </p>
              <p>{t("Terms_of_Use.intp2")}</p>
              <p> </p>
              <p>
                {t("Terms_of_Use.Y2mate_buzz_and_related_services.Overview")}
              </p>
              <p> </p>
              <p>
                {t(
                  "Terms_of_Use.Y2mate_buzz_and_related_services.Acceptance_of_Terms"
                )}
              </p>
              <p>
                {t(
                  "Terms_of_Use.Y2mate_buzz_and_related_services.Limits_on_Liability"
                )}
              </p>
              <p> </p>
              <p>
                <strong>
                  1. {t("Terms_of_Use.Eligibility_and_Accounts.title")}
                </strong>
              </p>
              <p> </p>
              <p>{t("Terms_of_Use.Eligibility_and_Accounts.Eligibility")}</p>
              <p> </p>
              <p>
                {t("Terms_of_Use.Eligibility_and_Accounts.Age_Requirement")}
              </p>
              <p> </p>
              <p>
                {t("Terms_of_Use.Eligibility_and_Accounts.Account_Creation")}
              </p>
              <p> </p>

              <p>
                {t(
                  "Terms_of_Use.Eligibility_and_Accounts.Account_Responsibility"
                )}
              </p>
              <p>
                {t("Terms_of_Use.Eligibility_and_Accounts.Unauthorized_Use")}
              </p>
              <p>
                {t("Terms_of_Use.Eligibility_and_Accounts.Unauthorized_Use1")}
              </p>
              <p>
                {t(
                  "Terms_of_Use.Eligibility_and_Accounts.Permission_to_Use_Service"
                )}
              </p>
              <p> </p>
              <p>
                <strong>2. {t("Terms_of_Use.Your_Right_to_Use.title")}</strong>
              </p>
              <p>{t("Terms_of_Use.Your_Right_to_Use.Permission")}</p>
              <p> </p>
              <ol>
                <li>{t("Terms_of_Use.Your_Right_to_Use.rules.r1")}</li>
              </ol>
              <p> </p>
              <ol>
                <li>{t("Terms_of_Use.Your_Right_to_Use.rules.r2")}</li>
              </ol>
              <p> </p>
              <ol>
                <li>{t("Terms_of_Use.Your_Right_to_Use.rules.r3")}</li>
              </ol>
              <p>{t("Terms_of_Use.Your_Right_to_Use.End_of_Permission")}</p>
              <ol>
                <li>{t("Terms_of_Use.Your_Right_to_Use.endRules.r1")}</li>
              </ol>

              <ol>
                <li>{t("Terms_of_Use.Your_Right_to_Use.endRules.r2")}</li>
              </ol>

              <ol>
                <li>{t("Terms_of_Use.Your_Right_to_Use.endRules.r3")}</li>
              </ol>
              <p>{t("Terms_of_Use.Your_Right_to_Use.p1")}</p>
              <p>{t("Terms_of_Use.Your_Right_to_Use.p2")}</p>
              <p>{t("Terms_of_Use.Your_Right_to_Use.Rules_and_Limits")}</p>
              <p> </p>
              <p>
                <strong>
                  3. {t("Terms_of_Use.Ownership_of_Content.title")}
                </strong>
              </p>
              <p> </p>
              <p> </p>
              <ol>
                <li>{t("Terms_of_Use.Ownership_of_Content.Ownership")}</li>
              </ol>
              <ol>
                <li>{t("Terms_of_Use.Ownership_of_Content.Protection")}</li>
              </ol>
              <ol>
                <li>{t("Terms_of_Use.Ownership_of_Content.replicate")}</li>
              </ol>
              <p> </p>
              <p>
                <strong>4. {t("Terms_of_Use.Your_Content.title")}</strong>
              </p>
              <p> </p>
              <p>a. {t("Terms_of_Use.Your_Content.Responsibility")}</p>
              <p>b. {t("Terms_of_Use.Your_Content.Standards")}</p>
              <p>c. {t("Terms_of_Use.Your_Content.Ownership_Rights")}</p>
              <p>{t("Terms_of_Use.Your_Content.understanding")}</p>
              <p> </p>
              <p>
                <strong>
                  5. {t("Terms_of_Use.Content_on_the_Service.title")}
                </strong>
              </p>
              <p> </p>
              <p>{t("Terms_of_Use.Content_on_the_Service.p1")}</p>
              <p> </p>
              <p>{t("Terms_of_Use.Content_on_the_Service.p2")}</p>
              <p> </p>
              <p>{t("Terms_of_Use.Content_on_the_Service.p3")}</p>
              <p> </p>
              <p>{t("Terms_of_Use.Content_on_the_Service.p4")}</p>
              <p> </p>
              <p>{t("Terms_of_Use.Content_on_the_Service.p5")}</p>
              <p> </p>
              <p>
                <strong>6. {t("Terms_of_Use.User_Conduct.title")}</strong>
              </p>
              <p> </p>
              <p>{t("Terms_of_Use.User_Conduct.desc")}</p>
              <p> </p>
              <p>
                {t("Terms_of_Use.User_Conduct.p1")}
                <br />
                {t("Terms_of_Use.User_Conduct.p2")}
                <br />
                {t("Terms_of_Use.User_Conduct.p3")}
                <br />
                {t("Terms_of_Use.User_Conduct.p4")}
                <br />
                {t("Terms_of_Use.User_Conduct.p5")}
                <br />
                {t("Terms_of_Use.User_Conduct.p6")}
                <br />
                {t("Terms_of_Use.User_Conduct.p7")}
                <br />
                {t("Terms_of_Use.User_Conduct.p8")}
                <br />
                {t("Terms_of_Use.User_Conduct.p9")}
                <br />
                {t("Terms_of_Use.User_Conduct.p10")}
                <br />
                {t("Terms_of_Use.User_Conduct.p11")}
                <br />
                {t("Terms_of_Use.User_Conduct.p12")}
                <br />
                {t("Terms_of_Use.User_Conduct.p13")}
                <br />
                {t("Terms_of_Use.User_Conduct.p14")}
                <br />
              </p>
              <p>{t("Terms_of_Use.User_Conduct.end")}</p>
              <p> </p>
              <p>
                <strong>
                  7. {t("Terms_of_Use.Services_on_the_Service.title")}
                </strong>
              </p>
              <p> </p>
              <p>{t("Terms_of_Use.Services_on_the_Service.p1")}</p>
              <p> </p>
              <p>{t("Terms_of_Use.Services_on_the_Service.p2")}</p>
              <p> </p>
              <p>
                <strong>8. {t("Terms_of_Use.Fees.title")}</strong>
              </p>
              <p> </p>
              <p>{t("Terms_of_Use.Fees.p1")}</p>
              <p> </p>
              <p>
                <strong>9. {t("Terms_of_Use.Privacy_Policy.title")}</strong>
              </p>
              <p> </p>
              <p>{t("Terms_of_Use.Privacy_Policy.p1")}</p>
              <p> </p>
              <p>{t("Terms_of_Use.Privacy_Policy.p2")}</p>
              <p> </p>
              <p>{t("Terms_of_Use.Privacy_Policy.p3")}</p>
              <p> </p>
              <p>
                <strong>10. {t("Terms_of_Use.Copyright_Claims.title")}</strong>
              </p>
              <p> </p>
              <p>{t("Terms_of_Use.Copyright_Claims.p1")}</p>
              <p> </p>
              <p>{t("Terms_of_Use.Copyright_Claims.p2")}</p>
              <p> </p>
              <p>{t("Terms_of_Use.Copyright_Claims.p3")}</p>
              <p> </p>
              <p>{t("Terms_of_Use.Copyright_Claims.p4")}</p>
              <p> </p>
              <p>{t("Terms_of_Use.Copyright_Claims.p5")}</p>
              <p> </p>
              <p>
                <strong>
                  11. {t("Terms_of_Use.Modification_of_These_Terms.title")}
                </strong>
              </p>
              <p> </p>
              <p>{t("Terms_of_Use.Modification_of_These_Terms.p1")}</p>
              <p> </p>
              <p>
                <strong>
                  12. {t("Terms_of_Use.Indemnification_and_Release.title")}
                </strong>
              </p>
              <p> </p>
              <p>{t("Terms_of_Use.Indemnification_and_Release.p1")}</p>
              <p> </p>
              <p>{t("Terms_of_Use.Indemnification_and_Release.p2")}</p>
              <p> </p>
              <p>
                <strong>
                  13. {t("Terms_of_Use.Disclaimer_and_Liabilities.title")}
                </strong>
              </p>
              <p>
                <strong>
                  {t("Terms_of_Use.Disclaimer_and_Liabilities.heading")}
                </strong>
              </p>
              <p>{t("Terms_of_Use.Disclaimer_and_Liabilities.p1")}</p>
              <p> </p>
              <p> {t("Terms_of_Use.Disclaimer_and_Liabilities.p2")}</p>
              <p> </p>
              <p>{t("Terms_of_Use.Disclaimer_and_Liabilities.p3")}</p>
              <p> </p>
              <p>{t("Terms_of_Use.Disclaimer_and_Liabilities.p4")}</p>
              <p> </p>
              <p>{t("Terms_of_Use.Disclaimer_and_Liabilities.p5")}</p>
              <p> </p>
              <p>{t("Terms_of_Use.Disclaimer_and_Liabilities.p6")}</p>
              <p> </p>
              <p>{t("Terms_of_Use.Disclaimer_and_Liabilities.p7")}</p>
              <p> </p>
              <p>
                <strong>14. {t("Terms_of_Use.Disputes.title")}</strong>
              </p>
              <p> </p>
              <p>{t("Terms_of_Use.Disputes.p1")}</p>
              <p> </p>
              <p>{t("Terms_of_Use.Disputes.p2")}</p>
              <p> </p>
              <p>
                <strong>{t("Terms_of_Use.Disputes.p3")}</strong>
              </p>
              <p> </p>
              <p>
                <strong>15. {t("Terms_of_Use.General_Terms.title")}</strong>
              </p>
              <p> </p>
              <p>{t("Terms_of_Use.General_Terms.p1")}</p>
              <p>{t("Terms_of_Use.General_Terms.p2")}</p>
              <p>{t("Terms_of_Use.General_Terms.p3")}</p>
              <p>{t("Terms_of_Use.General_Terms.p4")}</p>
              <p>{t("Terms_of_Use.General_Terms.p5")}</p>
              <p>{t("Terms_of_Use.General_Terms.p6")}</p>
              <p> </p>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default TermOfService;
