import React from "react";

const FeatureInstructions = (props) => {
  const instruction = JSON.parse(props.instruction)
  const features = JSON.parse(props.features)
  return (
    <div className="container">
      <div className="row row-padded row-bordered homepage-grid text-center p-l-md p-r-md">
        <div className="col-xs-12 col-sm-7 text-left">
          <h3>
            <strong>Instructions</strong>
          </h3>
          <p>
            1. {instruction.point_1}
          </p>
          <p>2. {instruction.point_2}</p>
          <p>
            3. {instruction.point_3}
          </p>
        </div>
        <div className="col-xs-12 col-sm-5 text-left">
          <h3>
            <strong>Features</strong>
          </h3>
          <p>• {features.point_1}</p>
          <p>• {features.point_2}</p>
          <p>• {features.point_3}</p>
          <p>• {features.point_4}</p>
          <p>• {features.point_5}</p>
        </div>
      </div>
    </div>
  );
};

export default FeatureInstructions;
