import { Routes, Route } from "react-router-dom";
import "./App.css";
import Main from "./components/sections/Main";
import Mp3Component from "./components/sections/Mp3Component";
import Mp4Component from "./components/sections/Mp4Component";
import ShortsComponent from "./components/sections/ShortsComponent";
import TermOfService from "./components/sections/TermOfService";
import ContactUs from "./components/sections/ContactUs";
import Faq from "./components/sections/Faq";
import PrivacyPolicy from "./components/sections/PrivacyPolicy";
import AboutUs from "./components/sections/AboutUs";
import content from "./components/assets/contentjson.json";
import Layout from "./components/layouts/Layout";
import { HelmetProvider } from "react-helmet-async";

function App() {
  return (
    <div className="App">
      <Layout>
        <HelmetProvider>
          <Routes>
            <Route
              path="/:lang?"
              element={
                <Main
                  content={content.youtubeDownloader}
                  mode="all"
                  tab="main"
                />
              }
            />
            <Route
              path="/:lang?/youtube-to-mp3"
              element={
                <Mp3Component
                  content={content.youtubeToMP3}
                  mode="mp3"
                  tab="mp3"
                />
              }
            />
            <Route
              path="/:lang?/youtube-to-mp4"
              element={
                <Mp4Component
                  content={content.youtubeToMP4}
                  mode="mp4"
                  tab="mp4"
                />
              }
            />
            <Route
              path="/:lang?/youtube-shorts"
              element={
                <ShortsComponent
                  content={content.youtubeShorts}
                  mode="all"
                  tab="shorts"
                />
              }
            />
            <Route
              path="/:lang?/terms-of-service"
              element={<TermOfService />}
            />
            <Route path="/:lang?/contact-us" element={<ContactUs />} />
            <Route path="/:lang?/faq" element={<Faq />} />
            <Route path="/:lang?/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/:lang?/about-us" element={<AboutUs />} />
          </Routes>
        </HelmetProvider>
      </Layout>
    </div>
  );
}

export default App;
