// Function to convert bytes to megabytes
const bytesToMB = (bytes) => {
    if (bytes === 0) return '0 MB';
    const mb = bytes / (1024 * 1024);
    return `${mb.toFixed(2)} MB`;
};

// Get file type from mimeType
const getFileType = (mimeType) => {
    const fileTypeRegex = /^([a-z]+)\/([a-z0-9-+.]+)(;.*)?$/i;
    const match = mimeType.match(fileTypeRegex);
    if (match && match.length >= 3) {
      return match[2];
    }
    return '';
  };

// get new array of data that is required
const getFormatedArray = (dataArray) => {
        if(dataArray){
        const results = dataArray.map((data) => {
            const fileSize = data.contentLength ? bytesToMB(parseInt(data.contentLength)) : 'Unknown';
            const fileType = getFileType(data.mimeType);
            const fileTypeWithQuality = `${data.qualityLabel ? data.qualityLabel: '' } (.${fileType})`;
        
            return {
                url: data.url,
                fileSize: fileSize,
                fileType: fileTypeWithQuality,
                itag: data.itag
            };
        });
        return results
    }
}

export default getFormatedArray;