import React, { useState } from 'react'
import ReactDOM from 'react-dom';
import '../assets/model.css';

const Portal = ({onClose, item, title}) => {
    const [modalRoot] = useState(() => document.getElementById('modal-root'));
    const modalElement = document.createElement('div');

    React.useEffect(() => {
        modalRoot.appendChild(modalElement);

        return () => {
            modalRoot.removeChild(modalElement);
        };
    }, [modalElement, modalRoot]);

    return ReactDOM.createPortal(
        <div className="modal-overlay" onClick={onClose}>
            <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                <h2>{title}</h2>
                <p>File Type : {item.fileType} -- File Size: {item.fileSize}</p>
                <div className="note-container">
                    <div className="note-icon">⋮⋮⋮</div>
                    <div className="note-message">Please wait file will start downloading soon.</div>
                </div>
                <iframe
                title="Download"
                src={item.url}
                width="100%"
                height="400px"
                ></iframe>
                <svg xmlns="http://www.w3.org/2000/svg" onClick={onClose} width="36" height="36" fill="currentColor" className="bi bi-x close-button" viewBox="0 0 16 16">
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"/>
                </svg>
            </div>
        </div>,
        modalElement
    );
}

export default Portal