import React, { useState, useEffect } from "react";
import logo from "../assets/images/y2mate_logo.png";
import { Navbar, Nav, Container, NavDropdown } from "react-bootstrap";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";

const languages = [
  { name: "English", code: "en", flag: "US" },
  { name: "Spanish", code: "es", flag: "ES" },
  { name: "French", code: "fr", flag: "FR" },
];

const Header = () => {
  const [check, setCheck] = useState("")
  const { t } = useTranslation();
  const [activeLink, setActiveLink] = useState("");
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // const timer = setTimeout(() => {
      changeLanguages();
    // }, 1000);

    // return () => clearTimeout(timer);
  }, [check]);

  const changeLanguages = () => {
    const currentPath = location.pathname;
    const langRegex = /^\/([a-z]{2})/;
    const match = location.pathname.match(langRegex);
    let newPath;
    if(match){
      setCheck(match[1])
      i18n.changeLanguage(match[1]);
    }
    
    if (!currentPath.startsWith("/en") && currentPath === "/") {
      newPath = `/en`;
      navigate(newPath, { replace: true });
    }
  };

  const handleLinkClick = (link) => {
    setActiveLink(link);
  };

  const changeLanguage = (lng) => {
    const currentPath = location.pathname;
    let newPath;
    let pathWithoutLang = currentPath.replace(/^\/[a-z]{2}/, "");
    newPath = `/${lng}${pathWithoutLang}`;

    i18n.changeLanguage(lng);
    navigate(newPath);
  };
  

  return (
    <div>
      <Navbar bg="light" expand="lg" sticky="top">
        <Container style={{ paddingTop: "15px" }}>
          {/* Logo */}
          <Link to={`/${i18n.language}`} className="mr-auto">
            <img
              src={logo}
              height="46"
              className="d-inline-block align-middle"
              alt="y2mate.buzz"
            />
            <span id="logo-name">y2mate.buzz</span>
          </Link>

          {/* Center Links */}
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="m-auto custom-nav-links">
              <Link
                to={`/${i18n.language}`}
                className={`nav-link ${activeLink === "/" ? "active" : ""}`}
                onClick={() => handleLinkClick("/")}
              >
                {t("home")}
              </Link>
              <Link
                to={`/${i18n.language}/youtube-to-mp3`}
                className={`nav-link ${
                  activeLink === "/youtube-to-mp3" ? "active" : ""
                }`}
                onClick={() => handleLinkClick("/youtube-to-mp3")}
              >
                {t("youtubeToMp3")}
              </Link>
              <Link
                to={`/${i18n.language}/youtube-to-mp4`}
                className={`nav-link ${
                  activeLink === "/youtube-to-mp4" ? "active" : ""
                }`}
                onClick={() => handleLinkClick("/youtube-to-mp4")}
              >
                {t("youtubeToMp4")}
              </Link>
              <Link
                to={`/${i18n.language}/youtube-shorts`}
                className={`nav-link ${
                  activeLink === "/youtube-shorts" ? "active" : ""
                }`}
                onClick={() => handleLinkClick("/youtube-shorts")}
              >
                {t("youtubeShorts")}
              </Link>
              {/* Language Dropdown */}
              <NavDropdown title={t("language")} id="basic-nav-dropdown">
                {languages.map((lang, index) => (
                  <NavDropdown.Item
                    key={index}
                    onClick={() => {
                      changeLanguage(lang.code);
                      setActiveLink(""); // Reset active link when language is changed
                    }}
                  >
                    <img
                      style={{ paddingRight: "5px" }}
                      src={`https://flagsapi.com/${lang.flag}/flat/24.png`}
                      alt={lang.name}
                      className="mr-2"
                    />
                    {lang.name}
                  </NavDropdown.Item>
                ))}
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

export default Header;
