import React from "react";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";

const ContactUs = () => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <Helmet>
        <meta
          name="description"
          content="If you have any questions, complaints, or claims regarding our Services, we're here to help. Your feedback is valuable to us, so please don't hesitate to reach out.You can send us a message."
        />
        <title>Contact US - Y2mate.buzz</title>
      </Helmet>
      <div className="container main-container">
        <div className="row">
          <div className="col-sm-12" style={{ background: "#ffffff" }}>
            <div
              className="blog-post"
              style={{ padding: "39px 10px", minHeight: "500px" }}
            >
              <p>
                <strong>{t("contact.title")}</strong>
              </p>
              <p>{t("contact.p1")}</p>
              <p>{t("contact.p2")}</p>
              <p>{t("contact.p3")}</p>
              <p> </p>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ContactUs;
