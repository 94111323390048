import React from "react";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";


const AboutUs = () => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <Helmet>
        <meta
          name="description"
          content="Welcome to Y2mate.buzz YouTube Downloader, your premier destination for high-quality video downloads. We specialise in providing free HD video services, offering a vast library of videos and music for you to explore and enjoy."
        />
        <title>About Us - Y2Mate.buzz</title>
      </Helmet>
      <div className="container main-container">
        <div className="row">
          <div className="col-sm-12" style={{ background: "#ffffff" }}>
            <div
              className="blog-post"
              style={{ padding: "39px 10px", minHeight: "500px" }}
            >
              <p>
                <strong>{t("aboutUs")}</strong>
              </p>
              <p>{t("about.p1")}</p>
              <p>{t("about.p2")}</p>
              <p>{t("about.p3")}</p>
              <p>{t("about.p4")}</p>
              <p></p>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AboutUs;
