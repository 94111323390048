import React, { useState } from "react";
import axios from "axios";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import Table from "react-bootstrap/Table";
import getFormatedArray from "../HelperFunctions";
import Portal from "../portals/Portal";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Banner = (props) => {
  const { t, i18n } = useTranslation();

  const [videoLink, setVideoLink] = useState("");
  const [key, setKey] = useState("video");

  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [title, setTitle] = useState("");
  const [thumbnail, setThumbnail] = useState("");
  const [autoFormat, setAutoFormat] = useState([]);
  const [audioFormats, setAudioFormats] = useState([]);
  const [videoWithAudioFormats, setVideoWithAudioFormats] = useState([]);
  const [videoWithOutAudioFormats, setvideoWithOutAudioFormats] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [modalItem, setModalItem] = useState(null); // Track the item for modal

  const handleTabSelect = (key) => {
    setKey(key);
  };

  const handleStart = () => {
    if (isValidYouTubeUrl(videoLink)) {
      setErrorMessage("");
      setSuccessMessage("");
      setIsLoading(true);

      axios
        .post("https://ytd.y2mate.buzz/api/get-youtube-info", { videoLink })
        .then((response) => {
          setTitle(response.data.title);
          setThumbnail(response.data.thumbnail);
          setAutoFormat(getFormatedArray([response.data.autoFormat]));
          setAudioFormats(getFormatedArray(response.data.audioFormats));
          setVideoWithAudioFormats(
            getFormatedArray(response.data.videoWithAudioFormats)
          );
          setvideoWithOutAudioFormats(
            getFormatedArray(response.data.videoWithOutAudioFormats)
          );

          setErrorMessage("");
          setSuccessMessage("Data Has Been Fetched");
        })
        .catch((error) => {
          setSuccessMessage("");
          setErrorMessage(
            "This Video is age restricted, and can't be accessed."
          );
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setErrorMessage(t("invalidURL"));
    }
  };

  const isValidYouTubeUrl = (url) => {
    // Regular expression for YouTube URL validation
    const youtubeRegex =
      /^(https?\:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/.+$/;
    return url.match(youtubeRegex);
  };

  const handleInputChange = (event) => {
    setVideoLink(event.target.value);
  };

  const openModal = (item) => {
    setModalItem(item);
  };

  const closeModal = () => {
    setModalItem(null);
  };

  return (
    <div
      className="main-banner d-flex justify-content-center align-items-center"
      id="banner"
    >
      <div className="text-center">
        <h1 className="gradient-heading">{props.title}</h1>
        <div
          className="form-inline form-search"
          action="/en943"
          id="search-form"
          name="mc-embedded-subscribe-form"
        >
          <input
            className="form-control input-lg"
            id="txt-url"
            name="query"
            type="text"
            placeholder={t("pasteVideoLink")}
            autoComplete="off"
            value={videoLink}
            onChange={handleInputChange}
          />
          <button
            title="Start"
            type="button"
            aria-label="Start"
            id="btn-submit"
            className="btn btn-lg"
            onClick={handleStart}
          >
            <span className="hidden-xs" style={{ color: "white" }}>
              {t("startButton")}
            </span>
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                fill="currentColor"
                className="bi bi-arrow-right-short"
                viewBox="0 0 16 16"
                style={{ color: "white" }}
              >
                <path
                  fillRule="evenodd"
                  d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8"
                />
              </svg>
            </span>
          </button>
          <div id="result-suggest-container"></div>
          <p className="small">
            {t("termsOfUse")}
            <Link
              className="active"
              style={{ padding: "6px" }}
              to={`/${i18n.language}/terms-of-service`}
              target="_blank"
            >
              {t("termsOfUseButton")}
            </Link>
            .
          </p>
          <img id="loading_img" src="/" style={{ display: "none" }} alt="" />
        </div>

        {errorMessage && <p className="btn btn-danger">{errorMessage}</p>}

        {!isLoading ? (
          successMessage && (
            <div>
              <div className="container">
                <div className="row">
                  <div className="col-md-12 col-sm-6">
                    <h1 style={{ fontSize: "3vw" }}>{title}</h1>
                    <img src={thumbnail} alt={title} className="img-fluid" />
                  </div>
                </div>
              </div>

              <div className="container mt-5" style={{ width: "80%" }}>
                <Tab.Container activeKey={key} onSelect={handleTabSelect}>
                  <Nav variant="tabs" className="mb-3">
                    <Nav.Item
                      className={`${
                        props.mode === "mp4" || props.mode === "all"
                          ? "active-visibility"
                          : "inActive-visibility"
                      }`}
                    >
                      <Nav.Link eventKey="video">Video</Nav.Link>
                    </Nav.Item>
                    <Nav.Item
                      className={`${
                        props.mode === "mp3" || props.mode === "all"
                          ? "active-visibility"
                          : "inActive-visibility"
                      }`}
                    >
                      <Nav.Link eventKey="audio">Audio</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="other">Other</Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content>
                    <Tab.Pane
                      eventKey="video"
                      className={`${
                        props.mode === "mp4" || props.mode === "all"
                          ? "active-visibility"
                          : "inActive-visibility"
                      }`}
                    >
                      <Table responsive>
                        <thead>
                          <tr>
                            <th>File Type</th>
                            <th>File Size</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {autoFormat &&
                            autoFormat.map((item, index) => {
                              return (
                                <tr key={index}>
                                  <td>{item.fileType}</td>
                                  <td>{item.fileSize}</td>
                                  <td>
                                    <a
                                      href={`https://ytd.y2mate.buzz/api/download/${item.itag}/${videoLink}`}
                                      className="btn btn-success"
                                      onClick={() => openModal(item)}
                                    >
                                      {" "}
                                      Download
                                    </a>

                                    {modalItem && modalItem === item && (
                                      <Portal
                                        onClose={closeModal}
                                        item={item}
                                        title={title}
                                      />
                                    )}
                                  </td>
                                </tr>
                              );
                            })}
                          {videoWithAudioFormats &&
                            videoWithAudioFormats.map((item, index) => {
                              return (
                                <tr key={index}>
                                  <td>{item.fileType}</td>
                                  <td>{item.fileSize}</td>
                                  <td>
                                    <a
                                      href={`https://ytd.y2mate.buzz/api/download/${item.itag}/${videoLink}`}
                                      className="btn btn-success"
                                      onClick={() => openModal(item)}
                                    >
                                      {" "}
                                      Download
                                    </a>

                                    {modalItem && modalItem === item && (
                                      <Portal
                                        onClose={closeModal}
                                        item={item}
                                        title={title}
                                      />
                                    )}
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </Table>
                    </Tab.Pane>
                    <Tab.Pane
                      eventKey="audio"
                      className={`${
                        props.mode === "mp3" || props.mode === "all"
                          ? "active-visibility"
                          : "inActive-visibility"
                      }`}
                    >
                      <Table responsive>
                        <thead>
                          <tr>
                            <th>File Type</th>
                            <th>File Size</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {audioFormats &&
                            audioFormats.map((item, index) => {
                              return (
                                <tr key={index}>
                                  <td>(.mp3)</td>
                                  <td>{item.fileSize}</td>
                                  <td>
                                    <a
                                      href={`https://ytd.y2mate.buzz/api/download/${item.itag}/${videoLink}`}
                                      className="btn btn-success"
                                      onClick={() => openModal(item)}
                                    >
                                      {" "}
                                      Download
                                    </a>

                                    {modalItem && modalItem === item && (
                                      <Portal
                                        onClose={closeModal}
                                        item={item}
                                        title={title}
                                      />
                                    )}
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </Table>
                    </Tab.Pane>
                    <Tab.Pane eventKey="other">
                      <Table responsive>
                        <thead>
                          <tr>
                            <th>File Type</th>
                            <th>File Size</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {videoWithOutAudioFormats &&
                            videoWithOutAudioFormats.map((item, index) => {
                              return (
                                <tr key={index}>
                                  <td>{item.fileType}</td>
                                  <td>{item.fileSize}</td>
                                  <td>
                                    <a
                                      href={`https://ytd.y2mate.buzz/api/download/${item.itag}/${videoLink}`}
                                      className="btn btn-success"
                                      onClick={() => openModal(item)}
                                    >
                                      {" "}
                                      Download
                                    </a>

                                    {modalItem && modalItem === item && (
                                      <Portal
                                        onClose={closeModal}
                                        item={item}
                                        title={title}
                                      />
                                    )}
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </Table>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>
            </div>
          )
        ) : (
          <div
            className="container"
            style={{ margin: "auto", width: "max-content" }}
          >
            <span className="loader"></span>
          </div>
        )}
      </div>
    </div>
  );
};

export default Banner;
