import React from "react";
import Banner from "../sections/Banner";
import Description from "../sections/Description";
import FeatureInstructions from "../sections/FeatureInstructions";
import Utility from "../sections/Utility";
import { useTranslation } from "react-i18next";

const Content = ({ content, mode, tab }) => {
  const { t } = useTranslation();

  // Define tab-specific content objects
  const tabContent = {
    shorts: {
      title: t("youtubeShortsTab.title"),
      subtitle: t("youtubeShortsTab.subtitle"),
      description: t("youtubeShortsTab.description"),
      instruction: t("youtubeShortsTab.instruction"),
      features: t("youtubeShortsTab.features"),
      cards: t("youtubeShortsTab.cards"),
    },
    main: {
      title: t("youtubeDownloader.title"),
      subtitle: t("youtubeDownloader.subtitle"),
      description: t("youtubeDownloader.description"),
      instruction: t("youtubeDownloader.instruction"),
      features: t("youtubeDownloader.features"),
      cards: t("youtubeDownloader.cards"),
    },
    mp3: {
      title: t("youtubeToMP3.title"),
      subtitle: t("youtubeToMP3.subtitle"),
      description: t("youtubeToMP3.description"),
      instruction: t("youtubeToMP3.instruction"),
      features: t("youtubeToMP3.features"),
      cards: t("youtubeToMP3.cards"),
    },
    mp4: {
      title: t("youtubeToMP4.title"),
      subtitle: t("youtubeToMP4.subtitle"),
      description: t("youtubeToMP4.description"),
      instruction: t("youtubeToMP4.instruction"),
      features: t("youtubeToMP4.features"),
      cards: t("youtubeToMP4.cards"),
    },
  };

  // Select content based on the tab

  const selectedContent = tabContent[tab] || content;
  return (
    <div>
      <Banner title={selectedContent.title || content.title} mode={mode} />
      <Description
        subtitle={selectedContent.subtitle || content.subtitle}
        description={selectedContent.description || content.description}
      />
      <FeatureInstructions
        instruction={selectedContent.instruction || content.instruction}
        features={selectedContent.features || content.features}
      />
      <Utility cards={selectedContent.cards || content.cards} />
    </div>
  );
};

export default Content;
