import React from "react";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";

const Faq = () => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <Helmet>
        <meta
          name="description"
          content="Q: Which platforms are supported by y2mate.buzz?
          Currently, we support downloads exclusively from YouTube.Q: What file formats can I convert videos into? At the moment, you can convert YouTube videos into MP3 (audio) or MP4 (video) formats."
        />
        <title>FAQ’s - Y2Mate.buzz</title>
      </Helmet>
      <div className="container main-container">
        <div className="row">
          <div className="col-sm-12" style={{ background: "#ffffff" }}>
            <div
              className="blog-post"
              style={{ padding: "39px 10px", minHeight: "500px" }}
            >
              <p>
                <b>{t("faq.title")}</b>
              </p>
              <p>
                <b>Q: {t("faq.Q1")}</b>
              </p>
              <p>{t("faq.A1")}</p>
              <p> </p>
              <p>
                <b>Q: {t("faq.Q2")}</b>
              </p>
              <p>{t("faq.A2")}</p>
              <p> </p>
              <p>
                <b>Q: {t("faq.Q3")}</b>
              </p>
              <p>{t("faq.A3.heading")}</p>
              <ul>
                <li>{t("faq.A3.p1")}</li>
                <li>{t("faq.A3.p2")}</li>
                <li>{t("faq.A3.p3")}</li>
              </ul>
              <p>{t("faq.A3.p4")}</p>
              <p> </p>
              <p>{t("faq.A3.p5")}</p>
              <p>
                <b>Q: {t("faq.Q4")}</b>
              </p>
              <p>{t("faq.A4")}</p>

              <p> </p>
              <p>
                <b>Q: {t("faq.Q5")}</b>
              </p>
              <p>{t("faq.A5")}</p>
              <p> </p>
              <p>
                <b>Q: {t("faq.Q6")}</b>
              </p>
              <p>{t("faq.A6")}</p>
              <p> </p>
              <p>
                <b>Q: {t("faq.Q7")}</b>
              </p>
              <p>{t("faq.A7")}</p>

              <p>
                <b>Q: {t("faq.Q8")}</b>
              </p>
              <p>{t("faq.A8")}</p>

              <p>
                <b>Q: {t("faq.Q9")}</b>
              </p>
              <p>{t("faq.A9")}</p>

              <p>
                <b>Q: {t("faq.Q10")}</b>
              </p>
              <p>{t("faq.A10")}</p>

              <p>
                <b>Q: {t("faq.Q11")}</b>
              </p>
              <p>{t("faq.A11")}</p>

              <p>
                <b>Q: {t("faq.Q12")}</b>
              </p>
              <p>{t("faq.A12")}</p>

              <p>
                <b>Q: {t("faq.A13")}</b>
              </p>
              <p>{t("faq.A13")}</p>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Faq;
