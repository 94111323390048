import React, { useState, useEffect } from "react";
import DownloadIcon from "../assets/images/download-Icon.png";
import CameraIcon from "../assets/images/camera-icon.png";
import CloudIcon from "../assets/images/cloud-download-icon.png";
import ThumbIcon from "../assets/images/thumb-icon.png";
import FreeConversion from "../assets/images/free-conversion.png";
import HammerIcon from "../assets/images/hammer.png";
import SupportIcon from "../assets/images/full-platform-support.png";
import EasyConvertingIcon from "../assets/images/easy-converting.png";

const Utility = (props) => {
  const cards = JSON.parse(props.cards)
  const [utilityItems, setUtilityItems] = useState([]);
  useEffect(() => {
    const cardsData = cards.map((card) => {
      let icon;
      switch (card.cardTitle) {
        case "Free Downloads":
          icon = DownloadIcon;
          break;
        case "Video & Audio":
          icon = CameraIcon;
          break;
        case "EASY DOWNLOADS":
          icon = ThumbIcon;
          break;
        case "Best to Download":
          icon = CloudIcon;
          break;
        case "Full platforms supported":
          icon = SupportIcon;
          break;
        case "Easily converting":
          icon = EasyConvertingIcon;
          break;
        case "YouTube to Mp3 Converter":
          icon = HammerIcon;
          break;
        case "Mp4 Converter":
            icon = CameraIcon;
            break;
        case "Free conversion":
          icon = FreeConversion;
          break;
        case "Full file format supported":
            icon = HammerIcon;
            break;
        default:
          icon = null;
          break;
      }
      return {
        ...card,
        icon: icon,
      };
    });

    setUtilityItems(cardsData);
  }, [props.cards]);

  return (
    <div className="container">
      <div className="row">
        {utilityItems.map((item, index) => (
          <div key={index} className="col-md-6 mt-4">
            <div className="utility">
              {item.icon && (
                <img src={item.icon} alt={item.title} height={64} width={64} />
              )}
              <h1>{item.cardTitle}</h1>
              <p>{item.cardBody}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Utility;
