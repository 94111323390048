import Content from "../layouts/Content";
import { Helmet } from "react-helmet-async";

const Mp3Component = (props) => {
  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="Quickly convert YouTube videos to high-quality MP3 audio files for free. Use our online converter tool at Y2mate.buzz and enjoy your favourite music offline!"
        />
        <title>Youtube to Mp3 Converter | Y2mate.buzz</title>
      </Helmet>
      <Content content={props.content} mode={props.mode} tab={props.tab} />
    </>
  );
};

export default Mp3Component;
